import * as lineupActions from '../actions/lineup'
import * as owsActions from '../actions/ows'
import getInitialSettingsForSport from '../utils/sport-settings'
import { owsProjectionsOverride } from '../utils/owsProjectionsOverride'

const initialState = (site='dk', sport='nfl', slate='Main') => ({
  opt: {
    loading: false,
    data: null,
    failedLoading: false,
    site: null,
    slate: null,
    lineupRunId: null,
    error_message: '',
    building: false
  },
  savedLineups: {
    loading: false,
    data: [],
    lineupHashes: {},
    failedLoading: false,
  },
  players: {
    loading: false,
    data: [],
    failedLoading: false,
    updatedAt: null
  },
  settings: {
    loading: false,
    data: getInitialSettingsForSport(sport, site, slate, true),
    failedLoading: false
  },
  teamStacks: {
    loading: false,
    data: [],
    failedLoading: false
  },
  exposureStats: {
    loading: false,
    data: [],
    failedLoading: false
  },
  slates: {
    loading: false,
    data: null,
    failedLoading: false
  },
  allSlates: {
    loading: false,
    data: {},
    failedLoading: false
  },
  lineupStats: {
    loading: false,
    data: null,
    failedLoading: false
  },
  presets: {
    loading: false,
    data: null,
    failedLoading: false
  },
  handbuildMode: {
    active: false
  },
  ows: {
    useJM: false
  },
  lineupRuns: {
    loading: false,
    data: [],
    failedLoading: false,
    updating: false,
    selectedLineupRunId: null
  },
  sims: {
    loading: false,
    slates: []
  }
})

const lineups = (
  state = initialState(),
  action
) => {
  switch (action.type) {
    case (lineupActions.opt.FETCH):
      return {
        ...state,
        opt: {
          ...state.opt,
          data: null,
          failedLoading: false,
          loading: true,
          error_message: false,
          building: true
        }
      }
    case (lineupActions.opt.NEW_LINEUP):
      return {
        ...state,
        opt: {
          ...state.opt,
          error_message: false,
          data: action.payload
        }
      }
    case (lineupActions.opt.RUN_COMPLETE):
      return {
        ...state,
        opt: {
          ...state.opt,
          building: false,
          loading: false
        }
      }
    case (lineupActions.opt.RECEIVE):
      return {
        ...state,
        ...{
          opt: {
            ...state.opt,
            data: action.payload,
            loading: false,
            failedLoading: false,
            site: action.site,
            slate: action.slate,
            error_message: false
          }
        }
      }
    case (lineupActions.opt.SET_LINEUP_RUN_RECEIVE):
      return {
        ...state,
        ...{
          opt: {
            ...state.opt,
            error_message: false,
            lineupRunId: action.lineupRunId
          },
          lineupRuns: {
            ...state.lineupRuns,
            selectedLineupRunId: action.lineupRunId
          }
        }
      }
    case (lineupActions.opt.FAIL_FETCH):
      return {
        ...state,
        ...{
          opt: {
            ...state.opt,
            loading: false,
            data: null,
            failedLoading: true
          }
        }
      }
    case (lineupActions.opt.CANCEL):
      return {
        ...state,
        ...{
          opt: {
            ...state.opt,
            loading: false,
            data: { ...state.opt.data },
            failedLoading: false,
            error_message: false
          }
        }
      }
    case (lineupActions.opt.CLEAR):
      return {
        ...state,
        ...{
          opt: {
            loading: false,
            data: null,
            failedLoading: false,
            site: null,
            slate: null
          }
        }
      }
    case (lineupActions.players.FETCH):
      return {
        ...state,
        players: {
          ...state.players,
          loading: true
        }
      }
    case (lineupActions.players.RECEIVE):
      return {
        ...state,
        ...{
          players: {
            loading: false,
            data: action.payload,
            failedLoading: false,
            updatedAt: action.updated_at
          }
        }
      }
    case (lineupActions.players.UPDATE):
      return {
        ...state,
        players: {
          ...state.players,
          data: action.payload
        }
      }
    case (lineupActions.players.REMOVE):
      return {
        ...state,
        players: {
          ...initialState('dk').players
        }
      }
    case (lineupActions.settings.FETCH):
      return {
        ...state,
        ...{
          settings: {
            ...state.settings,
            loading: true
          }
        }
      }
    case (lineupActions.settings.RECEIVE):
      return {
        ...state,
        ...{
          settings: {
            ...state.settings,
            loading: false,
            data: {
              ...state.settings.data,
              ...action.payload
            }
          }
        }
      }
    case (lineupActions.settings.OVERRIDE):
      // For now these are defaults so we want to save them differently
      let _settings = {...state.settings.data}
      Object.keys(action.payload).forEach(setting => {
        _settings[setting] = action.payload[setting]
      })

      return {
        ...state,
        ...{
          settings: {
            ...state.settings,
            loading: false,
            data: {
              ..._settings
            }
          }
        }
      }
    case (lineupActions.settings.UPDATE):
      return {
        ...state,
        settings: {
          ...state.settings,
          data: {
            ...state.settings.data,
            ...action.payload
          }
        }
      }
    case (lineupActions.teamStacks.FETCH):
      return {
        ...state,
        teamStacks: {
          ...state.teamStacks,
          loading: true
        }
      }
    case (lineupActions.teamStacks.RECEIVE):
      return {
        ...state,
        ...{
          teamStacks: {
            loading: false,
            data: action.payload,
            failedLoading: false
          }
        }
      }
    case (lineupActions.teamStacks.UPDATE):
      return {
        ...state,
        teamStacks: {
          ...state.teamStacks,
          data: action.payload
        }
      }
    case (lineupActions.exposureStats.FETCH):
      return {
        ...state,
        exposureStats: {
          ...state.exposureStats,
          loading: true
        }
      }
    case (lineupActions.exposureStats.RECEIVE):
      return {
        ...state,
        ...{
          exposureStats: {
            loading: false,
            data: action.payload,
            failedLoading: false
          }
        }
      }
    case (lineupActions.slates.FETCH):
      return {
        ...state,
        slates: {
          ...state.slates,
          loading: true
        }
      }
    case (lineupActions.slates.RECEIVE):
      return {
        ...state,
        ...{
          slates: {
            loading: false,
            data: action.payload,
            failedLoading: false
          }
        }
      }
    case (lineupActions.slates.FETCH_ALL):
      return {
        ...state,
        allSlates: {
          ...state.allSlates,
          loading: true
        }
      }
    case (lineupActions.slates.RECEIVE_ALL):
      return {
        ...state,
        ...{
          allSlates: {
            loading: false,
            data: action.payload,
            failedLoading: false
          }
        }
      }
    case (lineupActions.lineupStats.FETCH):
      return {
        ...state,
        lineupStats: {
          ...state.lineupStats,
          data: [],
          loading: true
        }
      }
    case (lineupActions.lineupStats.RECEIVE):
      return {
        ...state,
        ...{
          lineupStats: {
            loading: false,
            data: action.payload,
            failedLoading: false
          }
        }
      }
    case (lineupActions.savedLineups.FETCH):
      return {
        ...state,
        savedLineups: {
          ...state.lineupStats,
          loading: true
        }
      }
    case (lineupActions.savedLineups.RECEIVE):
      return {
        ...state,
        ...{
          savedLineups: {
            loading: false,
            data: action.payload,
            lineupHashes: action.lineupHashes,
            failedLoading: false
          }
        }
      }
    case (lineupActions.savedLineups.FAIL_FETCH):
      return {
        ...state,
        ...{
          savedLineups: {
            loading: false,
            data: [],
            lineupHashes: '',
            failedLoading: true
          }
        }
      }
    case (lineupActions.presets.FETCH):
      return {
        ...state,
        presets: {
          ...state.presets,
          loading: true
        }
      }
    case (lineupActions.presets.RECEIVE):
      return {
        ...state,
        ...{
          presets: {
            loading: false,
            data: action.payload,
            failedLoading: false
          }
        }
      }
    case (lineupActions.handbuildMode.UPDATE):
      return {
        ...state,
        ...{
          handbuildMode: {
            active: action.active
          }
        }
      }
    case (owsActions.JMProjections.FETCH):
      const overridePlayers = owsProjectionsOverride([...state.players.data], action.useJM)

      return {
        ...state,
        ...{
          ows: {
            useJM: action.useJM
          }
        },
        ...{
          players: {
            ...state.players,
            data: overridePlayers
          }
        }
      }
    case (lineupActions.lineupRuns.FETCH):
      return {
        ...state,
        lineupRuns: {
          ...state.lineupRuns,
          loading: true
        }
      }
    case (lineupActions.lineupRuns.RECEIVE):
      return {
        ...state,
        ...{
          lineupRuns: {
            ...state.lineupRuns,
            loading: false,
            data: action.payload || [],
            failedLoading: false,
            updating: false
          },
          opt: {
            ...state.opt,
            loading: false
          },
          sims: {
            ...state.sims,
            loading: false
          }
        }
      }
    case (lineupActions.lineupRuns.UPDATE):
      return {
        ...state,
        ...{
          lineupRuns: {
            ...state.lineupRuns,
            updating: true
          }
        }
      }
    case (lineupActions.lineupRuns.RESET):
      return {
        ...state,
        ...{
          lineupRuns: {
            loading: false,
            data: [],
            failedLoading: false,
            updating: false,
            selectedLineupRunId: null,
          },
          sims: {
            ...state.sims,
            slates: []
          }
        }
      }
    case (lineupActions.lineupRuns.SET_LINEUP_RUN):
      return {
        ...state,
        ...{
          lineupRuns: {
            ...state.lineupRuns,
            selectedLineupRunId: action.lineupRunId
          }
        }
      }
    case (lineupActions.lineupSims.FETCH):
      return {
        ...state,
        ...{
          opt: {
            ...state.opt,
            loading: true,
            error_message: false
          },
          sims: {
            ...state.sims,
            loading: true
          }
        }
      }
    case (lineupActions.lineups.DELETE):
      return {
        ...state,
        ...{
          opt: {
            ...state.opt,
            loading: true,
            error_message: false
          }
        }
      }
    case (lineupActions.opt.SET_ERROR):
      return {
        ...state,
        ...{
          opt: {
            ...state.opt,
            loading: false,
            error_message: action.message
          }
        }
      }
    case (lineupActions.sims.FETCH_SLATES):
      return {
        ...state,
        ...{
          sims: {
            ...state.sims,
            slates: []
          }
        }
      }
    case (lineupActions.sims.RECEIVE_SLATES):
      return {
        ...state,
        ...{
          sims: {
            ...state.sims,
            slates: action.slates
          }
        }
      }
    default:
      return state
  }
}

// SELECTOR
export const getPlayers = state => state.lineup.players.data
export const getSettings = state => state.lineup.settings.data
export const getTeamStacks = state => state.lineup.teamStacks.data
export const getSavedLineups = state => state.lineup.savedLineups.data
export const getOptLineups = state => state.lineup.opt.data
export const getLineupRuns = state => state.lineup.lineupRuns.data

export default lineups
